export default [

  {
    title: 'Linea de frente',
    route: 'linea-de-frente',
    icon: 'UserIcon',
  },
  {
    title: 'Profesional de la salud',
    route: 'profesional',
    icon: 'HeartIcon',
  },

]
